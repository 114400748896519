import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { faBomb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../style/help.css";

export function HelpMarker({
    children,
    showCallBack,
    className,
    icon = "pi-info-circle",
}) {
    return (
        <Button
            link
            type="button"
            className={`clear-borders ${className}`}
            pt={{ root: { className: "help-button-root" } }}
            onClick={showCallBack}
        >
            {children}
            <i className={`pi ${icon} ml-1`}></i>
        </Button>
    );
}

export function Help({ showCallBack, className, visible }) {
    const subject = () => {
        if (/ncto/.test(window.location.pathname)) {
            return "New Client Take On Support";
        } else {
            return "Magnify Support for Beaird Harris";
        }
    };
    return (
        <Dialog
            header={<h3 className="help-dialog-header">Need Assistance?</h3>}
            visible={visible}
            dismissableMask={true}
            onHide={() => showCallBack(false)}
            className="help-dialog"
        >
            <h4 className="help-topic-header help-center-text">
                Encountering issues or have questions? Don't hesitate to reach out to
                our support team.
                <br /> We're here to assist you every step of the way!
            </h4>
            <div className="help-dialog-content">
                <h4 className="help-topic-header"> Notification:</h4>
                <p>
                    If you receive an error message while using the application, take note
                    of the details provided. This information will help our support team
                    diagnose and resolve the issue efficiently.
                </p>
                <h4 className="help-topic-header">Troubleshooting Tips:</h4>
                <p>
                    Before seeking assistance, try refreshing the page or restarting your
                    browser. Sometimes, this simple step can resolve temporary issues.
                </p>
                <h4 className="help-topic-header">Contact Support:</h4>
                <p>
                    If the error persists or if you're unsure how to proceed, don't
                    hesitate to reach out to our dedicated support team for assistance.
                    Click on the email icon to initiate a conversation with one of our
                    representatives. Be sure to provide as much detail as possible about
                    the error you encountered for faster resolution.
                </p>
                <h4 className="help-topic-header">Security Concerns:</h4>
                <p>
                    If you have any concerns regarding the security of your account or the
                    application, please immediately contact our security team using the email button below and then click the reset page button.
                </p>
                <h4 className="help-topic-header">Error Reporting:</h4>
                <p>
                    Your feedback is valuable to us. If you encounter a recurring issue or
                    have suggestions for improvement, please report it to our support
                    team. We continuously strive to enhance the user experience based on
                    your feedback.
                </p>
            </div>
            <Button className="reset-button" onClick={() => {
                const confirmText = "Resetting will do the following:\nClear your local cache\nLog you out of Magnify\nReload the page\n\nDo you want to continue? Click 'OK' to continue."
                if (window.confirm(confirmText) === true) {
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.reload()
                } else {
                    showCallBack(false)
                }
            }}>
                <FontAwesomeIcon icon={faBomb} style={{ marginRight: '.5rem' }} />
                Reset Page
            </Button>
            <Button
                className="help-email-button"
                onClick={() => {
                    window.location = `mailto:bizintel@abacustechnologies.com?subject=${subject()}`;
                }}
            >
                <span className="pi pi-envelope mr-2" />
                Email Support Team
            </Button>
        </Dialog>
    );
}
