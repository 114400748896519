import { Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from "react-redux";

export default function PeopleNav() {
    const user = useSelector(state => state.user)
    
    const { PEOPLE_TEST, TEAM_HR } = user

    if (PEOPLE_TEST === 1) {
      return (
        <Dropdown>
            <Dropdown.Toggle variant="primary-bg brand-text">
                Magnify People
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item href="/people/reviews">Reviews</Dropdown.Item>
                {/* <Dropdown.Item href="/people/requests">Requests</Dropdown.Item> */}
                { TEAM_HR === 1 ? <Dropdown.Item href="/people/reviewapproval">Approve Team Reviews</Dropdown.Item> : <Fragment></Fragment>}
                <Dropdown.Divider />
                <Dropdown.Item href='/people/morale'>Weekly Check In</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
      )
    } else {
        <Fragment></Fragment>
    }
}
